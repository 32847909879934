import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactForm from "../components/ContactForm"


import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

//Images
import Yvan_monnet_001 from "../images/images/yvan_monnet/Five_Detail-01.jpg"

import Yvan_monnet_002 from "../images/images/yvan_monnet/2101_Montage_Blanc.jpg"
import Yvan_monnet_003 from "../images/images/yvan_monnet/2101_Montage_Bleu.jpg"
import Yvan_monnet_004 from "../images/images/yvan_monnet/2301_Cacao_Satin_Montage.jpg"
import Yvan_monnet_005 from "../images/images/yvan_monnet/Five_Black-01-1-comp.jpg"
import Yvan_monnet_006 from "../images/images/yvan_monnet/Five_Profil-02-Comp.jpg"
import Yvan_monnet_007 from "../images/images/yvan_monnet/Five_Sous-01_Web.jpg"
import Yvan_monnet_008 from "../images/images/yvan_monnet/2301_Blanc_VNoir.png"
import Yvan_monnet_009 from "../images/images/yvan_monnet/2301_Bleu_Lezard.png"
import Yvan_monnet_010 from "../images/images/yvan_monnet/2301-Cacao_3D.png"
import Yvan_monnet_011 from "../images/images/yvan_monnet/Blanc_3d.png"
import Yvan_monnet_012 from "../images/images/yvan_monnet/Bleu_3d.png"

import { Button, Modal } from "antd"


const options = {
  overlayColor: "rgba(44,47,76,0.9)",
  captionStyle: {
    captionColor: "#857e74",
    captionFontFamily: "Raleway, sans-serif",
    captionFontSize: "22px",
    captionFontWeight: "300",
    captionFontStyle: "capitalize",
  },
  buttonsStyle: {
    buttonsBackgroundColor: "#857e74",
    buttonsIconColor: "rgba(176,158,111,0.8)",
  },
  autoplaySpeed: 1500,
  transitionSpeed: 0,
  showCaption: false,
  showThumbnails: false,
}

const Horlogeries_yvan_monet = () => {

  //Hooks
  const [dataContact, setDataContact] = useState(false)

  //Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = (value_i) => {
    setShowModalContact(true)
    setDataContact(value_i)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
      <Layout>
        <SEO title="Montres Yvan Monnet"/>

        <div className="slideshow">
          <div className="usp">
            <div className="usp-txt-header">Yvan Monnet</div>
          </div>
        </div>
        <div className="border-gold-bottom"></div>


        <section>
          <div className="section-flex-01" style={{ margin: "0px" }}>

            <h2 className="h2-mobile" style={{ textAlign: "center" }}>Yvan Monnet</h2>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="watch-intro">
                <img className="img" style={{ margin: "0px", marginTop: "6%", borderRadius: "12px" }}
                     src={Yvan_monnet_001}
                     alt="Yvan Monnet"/>
                <p className="p-2 p-font ">
                  Cet artisan créateur est issu de plusieurs décennies de maîtrise au sein de deux des plus
                  prestigieuses
                  manufactures horlogères genevoises dans lesquelles il a découvert les subtilités de la conception de
                  la
                  boîte de montre.
                  Initié par des maîtres horlogers et des artisans d’Art au savoir-faire séculaire, il a activement
                  participé
                  à la conception et à la réalisation de modèles d’exception qui ont fait le bonheur d’amateurs avertis.
                </p>
              </div>
              <h5 style={{ color: "#333" }}>Une montre à hauteur d’Homme.</h5>
              <ul className="ul">
                <li className="li">Une réinterprétation du pentagone dans une version tout terrain, aussi élégante que
                  sportive.
                </li>
                <li className="li">Étanche jusqu’à 200 mètres.</li>
                <li className="li">Mouvement mécanique à remontage automatique.</li>
                <li className="li">Fond saphir transparent.</li>
                <li className="li">Couronne Vissée</li>
                <li className="li">Super-Luminova 3D bleu.</li>
              </ul>

              <h5 style={{ color: "#333" }}>En toute simplicité</h5>

              <p className="p-2 p-font">La boîte terminée à la main est réalisée par des artisans confirmés de Genève,
                fournisseurs de marques de prestige. La lunette de forme est construite sur cinq courbes tendues qui
                se croisent, avec des angles nets et une finition poli satiné. La glace, est assortie d’un joint
                d’étanchéité invisible logé dans la lunette. Le fond, qui reprend la forme de la lunette, est
                transparent et dévoile les belles finitions du mouvement mécanique. La couronne, vissée pour une
                étanchéité à 200m, reprend également la géométrie à cinq côtés du pentagone.La sophistication est
                visible aussi de nuit grâce à des appliques en Super-LumiNova 3D: de jour ce sont des rectangles, la
                nuit ce sont des pentagones qui s’illuminent.</p>

              <p className="p-2 p-font">
                La conception de la boîte à la géométrie disruptive aurait difficilement pu être développée sans une
                grande maîtrise des outils actuel de Conception Assistée par Ordinateur.
                Un usinage nécessitant agilité et expérience pour produire des boîtes de qualités en petites quantités
                et aux meilleurs coûts.
                Un geste parfait, un œil affûté pour réaliser une terminaison irréprochable qui magnifie l’harmonie du
                design.
              </p>

              <h5 style={{ color: "#333" }}>Mina</h5>

              <p className="p-2 p-font">
                Sa forme, immédiatement reconnaissable, allie originalité et sobriété avec une élégance rare. Les
                couleurs des cadrans répondent à une envie de s’affirmer et de s’émanciper des codes actuels de notre
                société. Le laqué bleu plaira aux femmes en quête d’originalité et de dynamisme. Le laqué blanc est
                plus tendre en apparence mais a l’avantage de s’adapter aux tenues en leur apportant une touche de
                fraîcheur. Le satiné cacao jongle avec l’astre solaire ; Il offre des reflets jouant avec le regard et
                une brillance unique. Les mouvements décorés sont visibles à travers un verre saphir monté sur le
                fond. Ils sauront séduire les femmes qui apprécient voir le battement du cœur du mécanisme, apportant
                une touche de douceur et de sensualité.
              </p>

              <ul className="ul">
                <li className="li">La première montre bracelet pentagonale</li>
                <li className="li">Mouvement Suisse à remontage manuel ou automatique.</li>
              </ul>

              <p className="p-2 p-font">
                A l’heure actuelle, au sein du grand public, rares sont ceux qui parviennent à déceler les infinités
                de subtilités dont Mina fait l’objet, comme d’ailleurs toutes les montres qui se réclament du même
                segment, mais dont la valeur est souvent multipliée par deux ou plus.
                De plus, elle est titulaire d’une originalité qui la rend immédiatement reconnaissable. Sa forme
                pentagonale, aux esthétiques si savamment polies qu’elles fusionnent avec de parfaits arrondis,
                incarne ce dont tant de marques rêvent de pouvoir disposer : une touche identitaire marquée, unique et
                à nulle autre pareille.
              </p>

              <h5 style={{ color: "#333" }}>Une Production Locale</h5>
              <p className="p-2 p-font">
                Les montres YvanMonneT sont produites à plus de 80% à Genève par des entreprises au savoir-faire
                reconnu dans la production de composants horloger haut de gamme.
              </p>
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <div id="gallery-with-links" className="container content content-gallery">
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_002} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_002}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_003} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_003}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_004} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_004}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_005} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_005}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_006} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_006}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_007} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_007}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_001} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_001}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_008} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_008}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_009} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_009}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_010} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_010}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_011} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_011}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                    <div className="img-container col-image-with-link">
                      <a href={Yvan_monnet_012} data-attribute="SRL"><img className="img-gallery"
                                                                          src={Yvan_monnet_012}
                                                                          alt="Yvan Monnet"/></a>
                    </div>
                  </div>
                </SRLWrapper>
              </SimpleReactLightbox>
              <div style={{ display: "flex", justifyContent: "center" }}><Button
                className="button-gold-box btn-horlogeries" onClick={() => showModalContactForm("Yvan Monnet")}>Nous
                contacter</Button></div>
            </div>

          </div>
        </section>

        <Modal
          title="Formulaire de contact"
          style={{ minWidth: "40vw", height: "80vh" }}
          visible={showModalContact}
          onOk={handleContactFormOk}
          onCancel={handleContactFormCancel}
          okButtonProps={{ style: { display: "none" } }}
        >

          <ContactForm page={dataContact}/>

        </Modal>

      </Layout>
    </>
  )
}

export default Horlogeries_yvan_monet
